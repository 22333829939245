import { Route, Routes } from "react-router-dom";
import TestPage from "./pages/TestPage";
import { ThemeProvider } from "@mui/material";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import theme from "./muiTheme";
import PaymentPage from "./pages/PaymentPage";
import {
  SocketContext,
  socketBackend,
  socketAutomation,
} from "./context/socket";

const queryClient = new QueryClient();

function App() {
  return (
    <SocketContext.Provider value={{ socketBackend, socketAutomation }}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/" element={<PaymentPage />} />
            <Route path="/test" element={<TestPage />} />
          </Routes>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </SocketContext.Provider>
  );
}
export default App;
