import { Button, Paper, Modal, Typography, Box } from "@mui/material";
import PayModal from "./PayModal";
import { setUserEmailPhone } from "../../store";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { PresentToAllSharp } from "@mui/icons-material";

import { useCreateOrder } from "../../hooks/paymentPageHooks";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  px: "20px",
  transform: "translate(-50%, -50%)",
  width: {
    xs: 350,
    md: 450,
  },
  bgcolor: "background.paper",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};

function Pay({ variant }) {
  console.log(variant);
  const dispatch = useDispatch();
  // const { userDetails } = useSelector((state) => {
  //   return state.paymentPage;
  // });

  const [localState, setLocalState] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (variant == 2) {
      window.location.href = "https://rzp.io/l/yvzfR0MaQ";
      return;
    }
    setOpen(true);
  };
  const handleClose = (user) => {
    // setLocalState()
    // window.sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
    // dispatch(setUserEmailPhone(user));
    setOpen(false);
  };

  return (
    <>
      <Modal keepMounted open={open} onClose={() => handleClose(localState)}>
        <Box sx={modalStyle}>
          <PayModal
            onClose={handleClose}
            localState={localState}
            setLocalState={setLocalState}
          />
        </Box>
      </Modal>
      <Paper
        sx={{
          borderRadius: "8px",
          background: "#FFF",
          padding: { xs: "20px 0px", md: "20px" },
          minWidth: "300px",
          position: { xs: "fixed", md: "relative" },
          bottom: { xs: "0" },

          width: { xs: "92%", md: "fit-content" },
        }}
        elevation={0}
      >
        <Button
          onClick={handleOpen}
          variant="contained"
          sx={{
            textTransform: "none",
            color: "#FFF",
            width: "100%",
            backgroundColor: "#389F6C",
            "&:hover": { backgroundColor: "#389F6C" },
            padding: "16px 24px",
          }}
          endIcon={<ArrowForwardIcon />}
        >
          Pay ₹3438
        </Button>
      </Paper>
    </>
  );
}
export default Pay;
